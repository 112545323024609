import useJwt from "@/auth/jwt/useJwt";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getSalePathData(ctx, salePath) {
      return useJwt.getSalePathData(salePath).then(response => response);
    },
  },
};
