import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import reservation from '@/views/contract/reservation/reservationStoreModule';
import user from '@/views/profile/userStoreModule';
import setting from '@/views/settings/settings/settingStoreModule';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import contract from './contract';
import apartment from '../views/property/apartment/apartmentStoreModule';
import fee from '../views/services/fee/feeStoreModule';
import report from './report';
import prepaid from '../views/finance/prepaid/prepaidStoreModule';
import order from './order';
import plan from '../views/pricing/pricingStoreModule';
import sale from './sale';
import room from './room';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    contract,
    apartment,
    report,
    fee,
    prepaid,
    order,
    plan,
    reservation,
    user,
    setting,
    sale,
    room,
  },
  strict: process.env.DEV,
});
