export default [
  {
    path: '/fee/list',
    name: 'fee-list',
    component: () => import('@/views/services/fee/list/FeeList.vue'),
    meta: {
      resource: 'fee',
      action: 'read',
    },
  },
  {
    path: '/quota/list',
    name: 'quota-list',
    component: () => import('@/views/services/quota/list/QuotaList.vue'),
    meta: {
      resource: 'quota',
      action: 'read',
    },
  },
  {

    path: '/meter/list',
    name: 'meter-list',
    component: () => import('@/views/services/meter/list/MeterList.vue'),
    meta: {
      resource: 'meter',
      action: 'read',
    },
  },
  {

    path: '/meter-log/list',
    name: 'meter-log-list',
    component: () => import('@/views/services/meter-log/list/MeterLogList.vue'),
    meta: {
      resource: 'meter-log',
      action: 'read',
    },
  },
];
