// eslint-disable-next-line import/no-cycle
import { getUserData } from '../utils';
import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      error => Promise.reject(error),
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response.data,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken()
              .then(r => {
                this.isAlreadyFetchingAccessToken = false;

                // Update accessToken in localStorage
                this.setToken(r.data.access_token);
                this.setRefreshToken(r.data.refresh_token);

                this.onAccessTokenFetched(r.data.access_token);
                return true;
              })
              .catch(() => {
                // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
                localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);

                // Remove userData from localStorage
                localStorage.removeItem('userData');
                this.onAccessTokenFetched(null);
                window.location.href = '/login';
                return false;
              });
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      },
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    });
  }

  firebaseToken(firebaseToken) {
    const savedClientToken = localStorage.getItem('clientToken');
    if (savedClientToken !== firebaseToken) {
      localStorage.setItem('clientToken', firebaseToken);
      return this.axiosIns.post(this.jwtConfig.firebaseTokenEndpoint, {
        firebaseToken,
      });
    }
    return firebaseToken;
  }

  // Common detail
  // Contact Group
  createItem(path, data) {
    return this.axiosIns.post(`/v1/${path}${this.jwtConfig.createEndpoint}`, data);
  }

  updateItem(path, { data, id }) {
    return this.axiosIns.put(`/v1/${path}${this.jwtConfig.updateEndpoint}${id}`, data);
  }

  downloadTemplateFile(path, data) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.downloadTemplateFileEndpoint}`, data);
  }

  importItem(path, data) {
    return this.axiosIns.post(`/v1/${path}${this.jwtConfig.importEndpoint}`, data);
  }

  exportItem(path, params) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.exportEndpoint}`, { params });
  }

  getItems(path, params) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.listEndpoint}`, {
      params,
    });
  }

  selectItem(path, params) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.selectEndpoint}`, {
      params,
    });
  }

  getItemDetail(path, id) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.detailEndpoint}${id}`);
  }

  getAnalytic(path, params) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.analyticsEndpoint}`, { params });
  }

  deleteItem(path, data) {
    return this.axiosIns.delete(`/v1/${path}${this.jwtConfig.deleteEndpoint}`, { data });
  }

  // apartment
  createApartment(...args) {
    return this.axiosIns.post(this.jwtConfig.apartmentCreateEndpoint, ...args);
  }

  updateApartment(data) {
    return this.axiosIns.put(`${this.jwtConfig.apartmentUpdateEndpoint}/${data.id}`, data);
  }

  importApartments(...args) {
    return this.axiosIns.post(this.jwtConfig.apartmentImportEndpoint, ...args);
  }

  exportApartments(params) {
    return this.axiosIns.get(this.jwtConfig.apartmentExportEndpoint, { params });
  }

  getApartments(params) {
    return this.axiosIns.get(this.jwtConfig.apartmentListEndpoint, {
      params,
    });
  }

  selectApartments(params) {
    return this.axiosIns.get(this.jwtConfig.apartmentSelectEndpoint, {
      params,
    });
  }

  getApartmentLayout(params) {
    return this.axiosIns.get(this.jwtConfig.apartmentLayoutEndpoint, {
      params,
    });
  }

  getApartmentDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.apartmentDetailEndpoint}/${id}`);
  }

  getApartmentAnalytics() {
    return this.axiosIns.get(this.jwtConfig.apartmentAnalyticsEndpoint);
  }

  getApartmentOccupancyRateAnalytics() {
    return this.axiosIns.get(this.jwtConfig.apartmentOccupancyRateAnalyticsEndpoint);
  }

  deleteApartments(data) {
    return this.axiosIns.delete(this.jwtConfig.apartmentDeleteEndpoint, { data });
  }

  fetchApartmentCalendar(params) {
    return this.axiosIns.get(this.jwtConfig.apartmentCalendarEndpoint, { params });
  }

  // utils
  getPricing() {
    return this.axiosIns.get(this.jwtConfig.utilsPricingEndpoint);
  }

  getCountries() {
    return this.axiosIns.get(this.jwtConfig.utilsCountriesEndpoint);
  }

  getVehicleTypes() {
    return this.axiosIns.get(this.jwtConfig.utilsVehicleTypesEndpoint);
  }

  getGenders() {
    return this.axiosIns.get(this.jwtConfig.utilsGendersEndpoint);
  }

  getUnits() {
    return this.axiosIns.get(this.jwtConfig.utilsUnitsEndpoint);
  }

  getPaymentPeriods() {
    return this.axiosIns.get(this.jwtConfig.utilsPaymentPeriodsEndpoint);
  }

  getProvince() {
    return this.axiosIns.get(this.jwtConfig.utilsProvinceEndpoint);
  }

  getAbilities() {
    return this.axiosIns.get(this.jwtConfig.utilsAbilitiesEndpoint);
  }

  getMeterTypes() {
    return this.axiosIns.get(this.jwtConfig.utilsMeterTypesEndpoint);
  }

  calculateQuantity(params) {
    return this.axiosIns.get(this.jwtConfig.utilsCalculateQuantityEndpoint, { params });
  }

  getDistricts(provinceId) {
    return this.axiosIns.get(
      this.jwtConfig.utilsDistrictEndpoint, { params: { provinceId } },
    );
  }

  getWards(districtId) {
    return this.axiosIns.get(
      this.jwtConfig.utilsWardEndpoint, { params: { districtId } },
    );
  }

  // room
  createRoom(...args) {
    return this.axiosIns.post(this.jwtConfig.roomCreateEndpoint, ...args);
  }

  updateRoom(data) {
    return this.axiosIns.put(`${this.jwtConfig.roomUpdateEndpoint}/${data.id}`, data);
  }

  getRooms(params) {
    return this.axiosIns.get(this.jwtConfig.roomListEndpoint, {
      params,
    });
  }

  selectRooms(params) {
    return this.axiosIns.get(this.jwtConfig.roomSelectEndpoint, {
      params,
    });
  }

  exportRooms(params) {
    return this.axiosIns.get(this.jwtConfig.roomExportEndpoint, {
      params,
    });
  }

  getRoomDetail(data) {
    const { roomId, isPublic } = data;
    if (isPublic) {
      return this.axiosIns.get(`${this.jwtConfig.roomDetailForSaleEndpoint}/${roomId}`);
    }
    return this.axiosIns.get(`${this.jwtConfig.roomDetailEndpoint}/${roomId}`);
  }

  getRoomAnalytic(params) {
    return this.axiosIns.get(this.jwtConfig.roomAnalyticEndpoint, { params });
  }

  deleteRooms(data) {
    return this.axiosIns.delete(this.jwtConfig.roomDeleteEndpoint, { data });
  }

  importRooms(...args) {
    return this.axiosIns.post(this.jwtConfig.roomImportEndpoint, ...args);
  }

  generateImportRoomTemplate(params) {
    return this.axiosIns.get(this.jwtConfig.roomGenerateImportTemplateEndpoint, { params });
  }

  // bed
  createBed(...args) {
    return this.axiosIns.post(this.jwtConfig.bedCreateEndpoint, ...args);
  }

  updateBed(data) {
    return this.axiosIns.put(`${this.jwtConfig.bedUpdateEndpoint}/${data.id}`, data);
  }

  getBedDetail(data) {
    const { bedId, isPublic } = data;
    if (isPublic) {
      return this.axiosIns.get(`${this.jwtConfig.bedDetailForSaleEndpoint}/${bedId}`);
    }
    return this.axiosIns.get(`${this.jwtConfig.bedDetailEndpoint}/${bedId}`);
  }

  getBeds(params) {
    return this.axiosIns.get(this.jwtConfig.bedListEndpoint, {
      params,
    });
  }

  exportBeds(params) {
    return this.axiosIns.get(this.jwtConfig.bedExportEndpoint, {
      params,
    });
  }

  getBedAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.bedAnalyticsEndpoint, {
      params,
    });
  }

  deleteBeds(data) {
    return this.axiosIns.delete(this.jwtConfig.bedDeleteEndpoint, { data });
  }

  // floor
  getFloors(params) {
    return this.axiosIns.get(this.jwtConfig.floorListEndpoint, {
      params,
    });
  }

  getFloorDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.floorListEndpoint}/${id}`);
  }

  deleteFloors(data) {
    return this.axiosIns.delete(this.jwtConfig.floorDeleteEndpoint, { data });
  }

  createFloor(...args) {
    return this.axiosIns.post(this.jwtConfig.floorCreateEndpoint, ...args);
  }

  updateFloor(...args) {
    return this.axiosIns.put(this.jwtConfig.floorUpdateEndpoint, ...args);
  }

  // fee
  createFee(...args) {
    return this.axiosIns.post(this.jwtConfig.feeCreateEndpoint, ...args);
  }

  updateFee(data) {
    return this.axiosIns.put(`${this.jwtConfig.feeUpdateEndpoint}/${data.id}`, data);
  }

  getFeeDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.feeDetailEndpoint}/${id}`);
  }

  getFees(params) {
    return this.axiosIns.get(this.jwtConfig.feeListEndpoint, {
      params,
    });
  }

  deleteFees(data) {
    return this.axiosIns.delete(this.jwtConfig.feeDeleteEndpoint, { data });
  }

  // fee type

  getFeeTypes() {
    return this.axiosIns.get(this.jwtConfig.feeTypeListEndpoint);
  }

  // contract
  createContract(...args) {
    return this.axiosIns.post(this.jwtConfig.contractCreateEndpoint, ...args);
  }

  updateContract(data) {
    return this.axiosIns.put(`${this.jwtConfig.contractUpdateEndpoint}/${data.id}`, data);
  }

  updateContractLeavingDate(...args) {
    return this.axiosIns.post(this.jwtConfig.contractUpdateLeavingDateEndpoint, ...args);
  }

  extendContract(...args) {
    return this.axiosIns.post(this.jwtConfig.contractExtendEndpoint, ...args);
  }

  moveContract(...args) {
    return this.axiosIns.post(this.jwtConfig.contractMoveEndpoint, ...args);
  }

  transferContract(...args) {
    return this.axiosIns.post(this.jwtConfig.contractTransferEndpoint, ...args);
  }

  liquidContract(...args) {
    return this.axiosIns.post(this.jwtConfig.contractLiquidEndpoint, ...args);
  }

  recoverContract(contractId) {
    return this.axiosIns.post(`${this.jwtConfig.contractRecoverEndpoint}/${contractId}`);
  }

  getContracts(params) {
    return this.axiosIns.get(this.jwtConfig.contractListEndpoint, {
      params,
    });
  }

  selectContracts(params) {
    return this.axiosIns.get(`${this.jwtConfig.contractListEndpoint}/select`, {
      params,
    });
  }

  getContractHTML(id) {
    return this.axiosIns.get(`${this.jwtConfig.contractHTMLEndpoint}/${id}`);
  }

  sendContractEmail(id) {
    return this.axiosIns.get(`${this.jwtConfig.contractSendEmailEndpoint}/${id}`);
  }

  sendContractSigningEmail(id) {
    return this.axiosIns.get(`${this.jwtConfig.contractSendSigningEmailEndpoint}/${id}`);
  }

  downloadContractPdf(id) {
    return this.axiosIns.get(`${this.jwtConfig.contractDownloadPdfEndpoint}/${id}`);
  }

  downloadContractDocuments(id) {
    return this.axiosIns.get(`${this.jwtConfig.contractDownloadDocumentsEndpoint}/${id}`);
  }

  getContractParams() {
    return this.axiosIns.get(this.jwtConfig.contractParamsEndpoint);
  }

  getContractAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.contractAnalyticsEndpoint, {
      params,
    });
  }

  getContractAnalyticsDashboard(params) {
    return this.axiosIns.get(this.jwtConfig.contractAnalyticsDashboardEndpoint, {
      params,
    });
  }

  getContractDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.contractDetailEndpoint}/${id}`);
  }

  deleteContracts(data) {
    return this.axiosIns.delete(this.jwtConfig.contractDeleteEndpoint, { data });
  }

  importContracts(...args) {
    return this.axiosIns.post(this.jwtConfig.contractImportEndpoint, ...args);
  }

  exportContracts(params) {
    return this.axiosIns.get(this.jwtConfig.contractExportEndpoint, {
      params,
    });
  }

  generateImportContractTemplate(params) {
    return this.axiosIns.get(this.jwtConfig.contractGenerateImportTemplateEndpoint, { params });
  }

  previewContract(data) {
    const { id, type } = data;
    return this.axiosIns.get(`${this.jwtConfig.contractPreviewEndpoint}/${id}/preview?type=${type}`);
  }

  getContractHandover(contractId) {
    return this.axiosIns.get(`${this.jwtConfig.contractHandoverEndpoint}/${contractId}`);
  }

  getContractHandoverLiquid(contractId) {
    return this.axiosIns.get(`${this.jwtConfig.contractHandoverLiquidEndpoint}/${contractId}`);
  }

  findContract(params) {
    return this.axiosIns.get(this.jwtConfig.contractFindEndpoint, {
      params,
    });
  }

  getContractSigningDocuments(params) {
    return this.axiosIns.get(this.jwtConfig.contractSigningDocumentsEndpoint, {
      params,
    });
  }

  requestContractSigningOTP(...args) {
    return this.axiosIns.post(this.jwtConfig.contractSigningOTPEndpoint, ...args);
  }

  signContract(...args) {
    return this.axiosIns.post(this.jwtConfig.contractSignEndpoint, ...args);
  }

  calculateRemainDiscount(contractId) {
    return this.axiosIns.get(`${this.jwtConfig.contractCalculateRemainDiscountEndpoint}/${contractId}`);
  }

  reCreateInvoiceForContract(contractId) {
    return this.axiosIns.post(`${this.jwtConfig.contractReCreateInvoiceEndpoint}/${contractId}`);
  }

  // income type
  createIncomeType(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeTypeCreateEndpoint, ...args);
  }

  updateIncomeType(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeTypeUpdateEndpoint, ...args);
  }

  getIncomeTypes(params) {
    return this.axiosIns.get(this.jwtConfig.incomeTypeListEndpoint, {
      params,
    });
  }

  deleteIncomeTypes(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeTypeDeleteEndpoint, ...args);
  }

  // income expense type
  createIncomeExpenseType(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseTypeCreateEndpoint, ...args);
  }

  updateIncomeExpenseType(data) {
    return this.axiosIns.put(`${this.jwtConfig.incomeExpenseTypeUpdateEndpoint}/${data.id}`, data);
  }

  getIncomeExpenseTypes(params) {
    return this.axiosIns.get(this.jwtConfig.incomeExpenseTypeListEndpoint, {
      params,
    });
  }

  deleteIncomeExpenseTypes(data) {
    return this.axiosIns.delete(this.jwtConfig.incomeExpenseTypeDeleteEndpoint, { data });
  }

  // expense type
  createExpenseType(...args) {
    return this.axiosIns.post(
      this.jwtConfig.expenseTypeCreateEndpoint,
      ...args,
    );
  }

  updateExpenseType(...args) {
    return this.axiosIns.post(
      this.jwtConfig.expenseTypeUpdateEndpoint,
      ...args,
    );
  }

  getExpenseTypes(params) {
    return this.axiosIns.get(this.jwtConfig.expenseTypeListEndpoint, {
      params,
    });
  }

  deleteExpenseTypes(...args) {
    return this.axiosIns.post(
      this.jwtConfig.expenseTypeDeleteEndpoint,
      ...args,
    );
  }

  // tenant
  createTenant(...args) {
    return this.axiosIns.post(this.jwtConfig.tenantCreateEndpoint, ...args);
  }

  updateTenant(objects) {
    const { id, data } = objects;
    return this.axiosIns.put(`${this.jwtConfig.tenantUpdateEndpoint}/${id}`, data);
  }

  importTenants(...args) {
    return this.axiosIns.post(this.jwtConfig.tenantImportEndpoint, ...args);
  }

  updateTenantResidenceInformation(...args) {
    return this.axiosIns.put(this.jwtConfig.tenantResidenceInformationEndpoint, ...args);
  }

  getTenantDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.tenantDetailEndpoint}/${id}`);
  }

  getTenantResidenceInformation(id) {
    return this.axiosIns.get(`${this.jwtConfig.tenantResidenceInformationEndpoint}/${id}`);
  }

  getCT01PdfUrl(id) {
    return this.axiosIns.get(`${this.jwtConfig.tenantResidenceInformationPdfEndpoint}/${id}`);
  }

  getTenants(params) {
    return this.axiosIns.get(this.jwtConfig.tenantListEndpoint, {
      params,
    });
  }

  exportTenants(params) {
    return this.axiosIns.get(this.jwtConfig.tenantExportEndpoint, {
      params,
    });
  }

  getTenantAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.tenantAnalyticsEndpoint, {
      params,
    });
  }

  getTenantAnalyticsDashboard(params) {
    return this.axiosIns.get(this.jwtConfig.tenantAnalyticsDashboardEndpoint, {
      params,
    });
  }

  deleteTenants(data) {
    return this.axiosIns.delete(this.jwtConfig.tenantDeleteEndpoint, { data });
  }

  // lead
  createLead(...args) {
    return this.axiosIns.post(this.jwtConfig.leadCreateEndpoint, ...args);
  }

  updateLead(objects) {
    const { id, data } = objects;
    return this.axiosIns.put(`${this.jwtConfig.leadUpdateEndpoint}/${id}`, data);
  }

  importLeads(...args) {
    return this.axiosIns.post(this.jwtConfig.leadImportEndpoint, ...args);
  }

  getLeadDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.leadDetailEndpoint}/${id}`);
  }

  getLeads(params) {
    return this.axiosIns.get(this.jwtConfig.leadListEndpoint, {
      params,
    });
  }

  exportLeads(params) {
    return this.axiosIns.get(this.jwtConfig.leadExportEndpoint, {
      params,
    });
  }

  getLeadAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.leadAnalyticsEndpoint, {
      params,
    });
  }

  deleteLeads(data) {
    return this.axiosIns.delete(this.jwtConfig.leadDeleteEndpoint, { data });
  }

  fowardLead(data) {
    return this.axiosIns.post(this.jwtConfig.leadFowardEndpoint, data);
  }

  markLeadAsFail(data) {
    return this.axiosIns.post(this.jwtConfig.leadMarkAsFailEndpoint, data);
  }

  getLeadSources() {
    return this.axiosIns.get(this.jwtConfig.leadSourcesEndpoint);
  }

  getLeadStatus() {
    return this.axiosIns.get(this.jwtConfig.leadStatusesEndpoint);
  }

  commentLead(data) {
    return this.axiosIns.post(this.jwtConfig.leadCommentEndpoint, data);
  }

  // tenant
  createVehicle(...args) {
    return this.axiosIns.post(this.jwtConfig.vehicleCreateEndpoint, ...args);
  }

  importVehicles(...args) {
    return this.axiosIns.post(this.jwtConfig.vehicleImportEndpoint, ...args);
  }

  updateVehicle(objects) {
    const { id, data } = objects;
    return this.axiosIns.put(`${this.jwtConfig.vehicleUpdateEndpoint}/${id}`, data);
  }

  getVehicles(params) {
    return this.axiosIns.get(this.jwtConfig.vehicleListEndpoint, {
      params,
    });
  }

  exportVehicles(params) {
    return this.axiosIns.get(this.jwtConfig.vehicleExportEndpoint, {
      params,
    });
  }

  generateImportVehicleTemplate() {
    return this.axiosIns.get(this.jwtConfig.vehicleGenerateImportTemplateEndpoint);
  }

  getVehicleDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.vehicleDetailEndpoint}/${id}`);
  }

  deleteVehicles(data) {
    return this.axiosIns.delete(this.jwtConfig.vehicleDeleteEndpoint, { data });
  }

  // reservation
  createReservation(...args) {
    return this.axiosIns.post(
      this.jwtConfig.reservationCreateEndpoint,
      ...args,
    );
  }

  updateReservation(data) {
    return this.axiosIns.put(
      `${this.jwtConfig.reservationUpdateEndpoint}/${data.id}`,
      data,
    );
  }

  terminateReservation(...args) {
    return this.axiosIns.post(
      this.jwtConfig.reservationTerminateEndpoint,
      ...args,
    );
  }

  getReservationDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.reservationDetailEndpoint}/${id}`);
  }

  getReservations(params) {
    return this.axiosIns.get(this.jwtConfig.reservationListEndpoint, {
      params,
    });
  }

  exportReservations(params) {
    return this.axiosIns.get(this.jwtConfig.reservationExportEndpoint, {
      params,
    });
  }

  getReservationAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.reservationAnalyticsEndpoint, {
      params,
    });
  }

  deleteReservations(data) {
    return this.axiosIns.delete(
      this.jwtConfig.reservationDeleteEndpoint,
      { data },
    );
  }

  getActiveReservation(params) {
    return this.axiosIns.get(this.jwtConfig.reservationGetActiveEndpoint, {
      params,
    });
  }

  previewReservation(id) {
    return this.axiosIns.get(`${this.jwtConfig.reservationPreviewEndpoint}/${id}/preview`);
  }

  // cashbook
  createCashbook(...args) {
    return this.axiosIns.post(this.jwtConfig.cashbookCreateEndpoint, ...args);
  }

  updateCashbook(data) {
    return this.axiosIns.put(`${this.jwtConfig.cashbookUpdateEndpoint}/${data.id}`, data);
  }

  getCashbooks(params) {
    return this.axiosIns.get(this.jwtConfig.cashbookListEndpoint, {
      params,
    });
  }

  getCashbookDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.cashbookDetailEndpoint}/${id}`);
  }

  deleteCashbooks(data) {
    return this.axiosIns.delete(this.jwtConfig.cashbookDeleteEndpoint, { data });
  }

  // provider
  createProvider(...args) {
    return this.axiosIns.post(this.jwtConfig.providerCreateEndpoint, ...args);
  }

  updateProvider(data) {
    return this.axiosIns.put(`${this.jwtConfig.providerUpdateEndpoint}/${data.id}`, data);
  }

  getProviders(params) {
    return this.axiosIns.get(this.jwtConfig.providerListEndpoint, {
      params,
    });
  }

  getProviderDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.providerDetailEndpoint}/${id}`);
  }

  deleteProviders(data) {
    return this.axiosIns.delete(this.jwtConfig.providerDeleteEndpoint, { data });
  }

  // warehouse
  createWarehouse(...args) {
    return this.axiosIns.post(this.jwtConfig.warehouseCreateEndpoint, ...args);
  }

  updateWarehouse(data) {
    return this.axiosIns.put(`${this.jwtConfig.warehouseUpdateEndpoint}/${data.id}`, data);
  }

  getWarehouses(params) {
    return this.axiosIns.get(this.jwtConfig.warehouseListEndpoint, {
      params,
    });
  }

  getWarehouseDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.warehouseDetailEndpoint}/${id}`);
  }

  deleteWarehouses(data) {
    return this.axiosIns.delete(this.jwtConfig.warehouseDeleteEndpoint, { data });
  }

  // asset type
  createAssetType(...args) {
    return this.axiosIns.post(this.jwtConfig.assetTypeCreateEndpoint, ...args);
  }

  updateAssetType(data) {
    return this.axiosIns.put(`${this.jwtConfig.assetTypeUpdateEndpoint}/${data.id}`, data);
  }

  getAssetTypes(params) {
    return this.axiosIns.get(this.jwtConfig.assetTypeListEndpoint, {
      params,
    });
  }

  getAssetTypeDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.assetTypeDetailEndpoint}/${id}`);
  }

  deleteAssetTypes(data) {
    return this.axiosIns.delete(this.jwtConfig.assetTypeDeleteEndpoint, { data });
  }

  exportAssetTypes(params) {
    return this.axiosIns.get(this.jwtConfig.assetTypeExportEndpoint, { params });
  }

  // general data
  createGeneralData(...args) {
    return this.axiosIns.post(this.jwtConfig.generalDataCreateEndpoint, ...args);
  }

  updateGeneralData(data) {
    return this.axiosIns.put(`${this.jwtConfig.generalDataUpdateEndpoint}/${data.id}`, data);
  }

  getGeneralDatas(params) {
    return this.axiosIns.get(this.jwtConfig.generalDataListEndpoint, {
      params,
    });
  }

  getGeneralDataDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.generalDataDetailEndpoint}/${id}`);
  }

  deleteGeneralDatas(data) {
    return this.axiosIns.delete(this.jwtConfig.generalDataDeleteEndpoint, { data });
  }

  exportGeneralDatas(params) {
    return this.axiosIns.get(this.jwtConfig.generalDataExportEndpoint, { params });
  }

  // asset
  createAsset(...args) {
    return this.axiosIns.post(this.jwtConfig.assetCreateEndpoint, ...args);
  }

  updateAsset(data) {
    return this.axiosIns.put(`${this.jwtConfig.assetUpdateEndpoint}/${data.id}`, data);
  }

  importAsset(...args) {
    return this.axiosIns.post(this.jwtConfig.assetImportEndpoint, ...args);
  }

  moveAsset(...args) {
    return this.axiosIns.post(this.jwtConfig.assetMoveEndpoint, ...args);
  }

  generateImportAssetTemplate(params) {
    return this.axiosIns.get(this.jwtConfig.assetGenerateImportTemplateEndpoint, { params });
  }

  getAssets(params) {
    return this.axiosIns.get(this.jwtConfig.assetListEndpoint, {
      params,
    });
  }

  getAssetLogs(params) {
    return this.axiosIns.get(this.jwtConfig.assetLogListEndpoint, {
      params,
    });
  }

  exportAssetLogs(params) {
    return this.axiosIns.get(this.jwtConfig.assetLogExportEndpoint, {
      params,
    });
  }

  getAssetDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.assetDetailEndpoint}/${id}`);
  }

  exportAssets(params) {
    return this.axiosIns.get(this.jwtConfig.assetExportEndpoint, {
      params,
    });
  }

  getAssetAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.assetAnalyticsEndpoint, {
      params,
    });
  }

  deleteAssets(data) {
    return this.axiosIns.delete(this.jwtConfig.assetDeleteEndpoint, { data });
  }

  // invoice
  createInvoice(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceCreateEndpoint, ...args);
  }

  createInvoicPayment(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceCreatePaymentEndpoint, ...args);
  }

  updateInvoice(data) {
    return this.axiosIns.put(`${this.jwtConfig.invoiceUpdateEndpoint}/${data.id}`, data);
  }

  updateInvoiceTime(data) {
    return this.axiosIns.post(this.jwtConfig.invoiceUpdateTimeEndpoint, data);
  }

  generateMultipleInvoices(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceGenerateMultipleEndpoint, ...args);
  }

  generateImportInvoiceTemplate(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceGenerateImportTemplateEndpoint, { params });
  }

  getInvoices(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceListEndpoint, {
      params,
    });
  }

  getInvoiceHtml(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceHtmlEndpoint, {
      params,
    });
  }

  previewInvoice(invoiceId) {
    return this.axiosIns.get(`${this.jwtConfig.invoicePreviewEndpoint}/${invoiceId}`);
  }

  previewInvoiceWithUUID(params) {
    return this.axiosIns.get(this.jwtConfig.invoicePreviewWithUUIDEndpoint, { params });
  }

  getInvoicePdf(params) {
    return this.axiosIns.get(this.jwtConfig.invoicePdfEndpoint, {
      params,
    });
  }

  getInvoiceImage(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceImageEndpoint, {
      params,
    });
  }

  sendInvoiceViaZaloZNS(data) {
    return this.axiosIns.post(this.jwtConfig.invoiceSendViaZaloZNSEndpoint, { invoiceIds: data });
  }

  sendInvoiceViaZaloNormal(invoiceId) {
    return this.axiosIns.get(`${this.jwtConfig.invoiceSendViaZaloNormalEndpoint}/${invoiceId}`);
  }

  sendInvoiceViaApp(data) {
    return this.axiosIns.post(this.jwtConfig.invoiceSendViaAppEndpoint, { invoiceIds: data });
  }

  sendInvoiceViaEmail(data) {
    return this.axiosIns.post(this.jwtConfig.invoiceSendViaEmailEndpoint, { invoiceIds: data });
  }

  sendInvoices(data) {
    return this.axiosIns.post(this.jwtConfig.invoiceSendEndpoint, data);
  }

  exportInvoices(params) {
    const userData = getUserData();
    if (userData && (userData.id === 2353 || (userData.manager && userData.manager.ownerId === 2353))) {
      return this.axiosIns.get(this.jwtConfig.reportPvhExportEndpoint, {
        params,
      });
    }
    return this.axiosIns.get(this.jwtConfig.invoiceExportEndpoint, {
      params,
    });
  }

  exportInvoicesAsZip(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceExportZipEndpoint, {
      params,
    });
  }

  exportInvoicesAsZipPdf(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceExportZipPdfEndpoint, {
      params,
    });
  }

  importInvoices(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceImportEndpoint, ...args);
  }

  getInvoiceAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceAnalyticsEndpoint, {
      params,
    });
  }

  getInvoiceDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.invoiceDetailEndpoint}/${id}`);
  }

  getIncomeExpenseForInvoice(id) {
    return this.axiosIns.get(`${this.jwtConfig.invoiceGetIncomeExpenseEndpoint}/${id}`);
  }

  deleteInvoices(data) {
    return this.axiosIns.delete(this.jwtConfig.invoiceDeleteEndpoint, { data });
  }

  approveInvoice(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceApproveEndpoint, ...args);
  }

  // role
  createRole(...args) {
    return this.axiosIns.post(this.jwtConfig.roleCreateEndpoint, ...args);
  }

  updateRole(data) {
    return this.axiosIns.put(`${this.jwtConfig.roleUpdateEndpoint}/${data.id}`, data);
  }

  getRoles(params) {
    return this.axiosIns.get(this.jwtConfig.roleListEndpoint, {
      params,
    });
  }

  getRoleDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.roleDetailEndpoint}/${id}`);
  }

  getRoleAbilities(id) {
    return this.axiosIns.get(`${this.jwtConfig.roleAbilitiesEndpoint}/${id}`);
  }

  deleteRoles(data) {
    return this.axiosIns.delete(this.jwtConfig.roleDeleteEndpoint, { data });
  }

  // manager
  createManager(...args) {
    return this.axiosIns.post(this.jwtConfig.managerCreateEndpoint, ...args);
  }

  updateManager(data) {
    return this.axiosIns.put(`${this.jwtConfig.managerUpdateEndpoint}/${data.id}`, data);
  }

  getManagers(params) {
    return this.axiosIns.get(this.jwtConfig.managerListEndpoint, {
      params,
    });
  }

  deleteManagers(data) {
    return this.axiosIns.delete(this.jwtConfig.managerDeleteEndpoint, { data });
  }

  getManagerDetail(params) {
    return this.axiosIns.get(this.jwtConfig.managerDetailEndpoint, {
      params,
    });
  }

  // income
  createIncome(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeCreateEndpoint, ...args);
  }

  updateIncome(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeUpdateEndpoint, ...args);
  }

  getIncomes(params) {
    return this.axiosIns.get(this.jwtConfig.incomeListEndpoint, {
      params,
    });
  }

  deleteIncomes(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeDeleteEndpoint, ...args);
  }

  // expense
  createExpense(...args) {
    return this.axiosIns.post(this.jwtConfig.expenseCreateEndpoint, ...args);
  }

  updateExpense(...args) {
    return this.axiosIns.post(this.jwtConfig.expenseUpdateEndpoint, ...args);
  }

  getExpenses(params) {
    return this.axiosIns.get(this.jwtConfig.expenseListEndpoint, {
      params,
    });
  }

  deleteExpenses(...args) {
    return this.axiosIns.post(this.jwtConfig.expenseDeleteEndpoint, ...args);
  }

  // income expense
  createIncomeExpense(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseCreateEndpoint, ...args);
  }

  updateIncomeExpense(data) {
    return this.axiosIns.put(`${this.jwtConfig.incomeExpenseUpdateEndpoint}/${data.id}`, data);
  }

  calculateAllocation(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseCalculateAllocationEndpoint, ...args);
  }

  getIncomeExpenses(params) {
    return this.axiosIns.get(this.jwtConfig.incomeExpenseListEndpoint, {
      params,
    });
  }

  exportIncomeExpenses(params) {
    return this.axiosIns.get(this.jwtConfig.incomeExpenseExportEndpoint, {
      params,
    });
  }

  getIncomeExpenseHTML(id) {
    return this.axiosIns.get(`${this.jwtConfig.incomeExpenseHTMLEndpoint}/${id}`);
  }

  getIncomeExpenseDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.incomeExpenseDetailEndpoint}/${id}`);
  }

  getIncomeExpenseAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.incomeExpenseAnalyticsEndpoint, {
      params,
    });
  }

  deleteIncomeExpenses(data) {
    return this.axiosIns.delete(this.jwtConfig.incomeExpenseDeleteEndpoint, { data });
  }

  importIncomeExpense(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseImportEndpoint, ...args);
  }

  generateImportIncomeExpensesTemplate(params) {
    return this.axiosIns.get(this.jwtConfig.incomeExpenseGenerateImportTemplateEndpoint, { params });
  }

  approveIncomeExpenses(...args) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseApproveEndpoint, ...args);
  }

  previewIncomeExpense(id) {
    return this.axiosIns.get(`${this.jwtConfig.incomeExpensePreviewEndpoint}/${id}`);
  }

  linkInvoice(data) {
    return this.axiosIns.post(this.jwtConfig.incomeExpenseLinkInvoiceEndpoint, data);
  }

  // quota
  createQuota(...args) {
    return this.axiosIns.post(this.jwtConfig.quotaCreateEndpoint, ...args);
  }

  updateQuota(data) {
    return this.axiosIns.put(`${this.jwtConfig.quotaUpdateEndpoint}/${data.id}`, data);
  }

  getQuotas(params) {
    return this.axiosIns.get(this.jwtConfig.quotaListEndpoint, {
      params,
    });
  }

  deleteQuotas(data) {
    return this.axiosIns.delete(this.jwtConfig.quotaDeleteEndpoint, { data });
  }

  getQuotaDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.quotaDetailEndpoint}/${id}`);
  }

  // task
  createTask(...args) {
    return this.axiosIns.post(this.jwtConfig.taskCreateEndpoint, ...args);
  }

  updateTask(object) {
    const { id, data } = object;
    return this.axiosIns.post(`${this.jwtConfig.taskUpdateEndpoint}/${id}`, data);
  }

  updateTaskStatus(...args) {
    return this.axiosIns.post(this.jwtConfig.taskUpdateStatusEndpoint, ...args);
  }

  getTasks(params) {
    return this.axiosIns.get(this.jwtConfig.taskListEndpoint, {
      params,
    });
  }

  getTaskDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.taskDetailEndpoint}/${id}`);
  }

  getTaskAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.taskAnalyticsEndpoint, {
      params,
    });
  }

  getTaskAnalyticsDashboard(params) {
    return this.axiosIns.get(this.jwtConfig.taskAnalyticsDashboardEndpoint, {
      params,
    });
  }

  deleteTasks(data) {
    return this.axiosIns.delete(this.jwtConfig.taskDeleteEndpoint, { data });
  }

  commentTask(data) {
    return this.axiosIns.post(this.jwtConfig.taskCommentEndpoint, data);
  }

  exportTask(params) {
    return this.axiosIns.get(this.jwtConfig.taskExportEndpoint, { params });
  }

  // meter
  createMeter(...args) {
    return this.axiosIns.post(this.jwtConfig.meterCreateEndpoint, ...args);
  }

  updateMeter(data) {
    return this.axiosIns.put(`${this.jwtConfig.meterUpdateEndpoint}/${data.id}`, data);
  }

  getMeters(params) {
    return this.axiosIns.get(this.jwtConfig.meterListEndpoint, {
      params,
    });
  }

  getMeterDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.meterDetailEndpoint}/${id}`);
  }

  deleteMeters(data) {
    return this.axiosIns.delete(this.jwtConfig.meterDeleteEndpoint, { data });
  }

  // notification
  createNotification(...args) {
    return this.axiosIns.post(this.jwtConfig.notificationCreateEndpoint, ...args);
  }

  updateNotification(object) {
    const { id, data } = object;
    return this.axiosIns.post(`${this.jwtConfig.notificationUpdateEndpoint}/${id}`, data);
  }

  getNotifications(params) {
    return this.axiosIns.get(this.jwtConfig.notificationListEndpoint, {
      params,
    });
  }

  getSystemNotifications(params) {
    return this.axiosIns.get(this.jwtConfig.systemNotificationListEndpoint, {
      params,
    });
  }

  markSystemNotificationsAsRead() {
    return this.axiosIns.post(this.jwtConfig.systemNotificationMarkAsReadListEndpoint);
  }

  getUnreadSystemNotifications(params) {
    return this.axiosIns.get(this.jwtConfig.systemNotificationCountUnreadListEndpoint, {
      params,
    });
  }

  getNotificationDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.notificationDetailEndpoint}/${id}`);
  }

  deleteNotifications(data) {
    return this.axiosIns.delete(this.jwtConfig.notificationDeleteEndpoint, { data });
  }

  // meter
  getChatsAndContacts(...args) {
    return this.axiosIns.get(this.jwtConfig.chatListChatAndContactEndpoint, ...args);
  }

  sendMessage(...args) {
    return this.axiosIns.post(this.jwtConfig.messageSendEndpoint, ...args);
  }

  getChatDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.chatProfileEndpoint}/${id}`);
  }

  getChatProfile(...args) {
    return this.axiosIns.get(this.jwtConfig.chatProfileEndpoint, ...args);
  }

  // meter log
  createMeterLog(...args) {
    return this.axiosIns.post(this.jwtConfig.meterLogCreateEndpoint, ...args);
  }

  updateMeterLog(data) {
    return this.axiosIns.post(this.jwtConfig.meterLogUpdateEndpoint, data);
  }

  getMeterLogs(params) {
    return this.axiosIns.get(this.jwtConfig.meterLogListEndpoint, {
      params,
    });
  }

  exportMeterLogs(params) {
    return this.axiosIns.get(this.jwtConfig.meterLogExportEndpoint, {
      params,
    });
  }

  getMeterLogAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.meterLogAnalyticsEndpoint, {
      params,
    });
  }

  getMeterLogDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.meterLogDetailEndpoint}/${id}`);
  }

  deleteMeterLogs(data) {
    return this.axiosIns.delete(this.jwtConfig.meterLogDeleteEndpoint, { data });
  }

  approveMeterLogs(...args) {
    return this.axiosIns.post(this.jwtConfig.meterLogApproveEndpoint, ...args);
  }

  generateImportMeterLogTemplate(params) {
    return this.axiosIns.get(this.jwtConfig.meterLogGenerateImportTemplateEndpoint, { params });
  }

  importMeterLogs(...args) {
    return this.axiosIns.post(this.jwtConfig.meterLogImportEndpoint, ...args);
  }

  // Permission
  getPermissions() {
    return this.axiosIns.get(this.jwtConfig.permissionListEndPoint);
  }

  getPermissionGroups() {
    return this.axiosIns.get(this.jwtConfig.permissionGroupListEndPoint);
  }

  // user
  getUserProfile() {
    return this.axiosIns.get(this.jwtConfig.userProfileEndpoint);
  }

  updateUserProfile(...args) {
    return this.axiosIns.post(this.jwtConfig.userProfileUpdateEndpoint, ...args);
  }

  verifyEmail(...args) {
    return this.axiosIns.post(this.jwtConfig.userVerifyEmailEndpoint, ...args);
  }

  verifyPhone(...args) {
    return this.axiosIns.post(this.jwtConfig.userVerifyPhoneEndpoint, ...args);
  }

  getACL(...args) {
    return this.axiosIns.get(this.jwtConfig.userACLEndpoint, ...args);
  }

  requestVerifyEmail(...args) {
    return this.axiosIns.post(this.jwtConfig.userRequestVerifyEmailEndpoint, ...args);
  }

  requestMissCallOTP(...args) {
    return this.axiosIns.post(this.jwtConfig.userRequestMissCallOTPEndpoint, ...args);
  }

  requestVerifyPhoneOtp(...args) {
    return this.axiosIns.post(this.jwtConfig.userRequestVerifyPhoneOTPEndpoint, ...args);
  }

  forgotPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotPasswordEndpoint, ...args);
  }

  resetPasswordWithOTP(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPasswordEndpoint, ...args);
  }

  changeUserPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.userChangePasswordEndpoint, ...args);
  }

  // dashboard
  getDashboard() {
    return this.axiosIns.get(this.jwtConfig.dashboardEndpoint);
  }

  // configuration
  getUserConfiguration() {
    return this.axiosIns.get(this.jwtConfig.userConfigurationEndpoint);
  }

  updateUserConfiguration(...args) {
    return this.axiosIns.post(this.jwtConfig.userConfigurationUpdateEndpoint, ...args);
  }

  getEmailConfiguration() {
    return this.axiosIns.get(this.jwtConfig.emailConfigurationEndpoint);
  }

  updateEmailConfiguration(...args) {
    return this.axiosIns.put(this.jwtConfig.emailConfigurationUpdateEndpoint, ...args);
  }

  getApartmentConfiguration(params) {
    return this.axiosIns.get(this.jwtConfig.apartmentConfigurationEndpoint, { params });
  }

  updateApartmentConfiguration(data) {
    return this.axiosIns.put(`${this.jwtConfig.apartmentConfigurationUpdateEndpoint}`, data);
  }

  // report
  getReportFinanceByYear(params) {
    return this.axiosIns.post(this.jwtConfig.reportFinanceByYear, { params });
  }

  getReportPropertyGeneral() {
    return this.axiosIns.get(this.jwtConfig.reportPropertyGeneral);
  }

  getReportPropertyCounting() {
    return this.axiosIns.get(this.jwtConfig.reportPropertyCounting);
  }

  getReportPropertyDetail() {
    return this.axiosIns.get(this.jwtConfig.reportPropertyDetail);
  }

  getReportFinanceCashFlow() {
    return this.axiosIns.get(this.jwtConfig.reportFinanceCashFlow);
  }

  getReportFinanceCounting() {
    return this.axiosIns.get(this.jwtConfig.reportFinanceCounting);
  }

  getReportFinanceDeposit(params) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceDeposit, { params });
  }

  exportReportFinanceDeposit(params) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceDepositExport, { params });
  }

  getReportNewDebt(params) {
    return this.axiosIns.get(this.jwtConfig.reportNewDebt, { params });
  }

  exportReportNewDebt(params) {
    return this.axiosIns.get(this.jwtConfig.reportNewDebtExport, { params });
  }

  exportReportBillingCalendar(params) {
    return this.axiosIns.get(this.jwtConfig.reportBillingCalendarExport, { params });
  }

  getReportFinancePrepaid() {
    return this.axiosIns.get(this.jwtConfig.reportFinancePrepaid);
  }

  getReportFinanceByMonth(params) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceByMonth, { params });
  }

  getAnalyticsReportFinanceByMonth(params) {
    return this.axiosIns.get(this.jwtConfig.reportAnalyticsFinanceByMonth, { params });
  }

  exportAnalyticsReportFinanceByMonth(params) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceByMonthExport, { params });
  }

  // report property
  getReportPropertyGeneralData(apartment) {
    return this.axiosIns.get(this.jwtConfig.reportPropertyGeneralData, { params: { apartmentId: apartment ? apartment.id : null } });
  }

  getReportPropertySlotsData(apartment) {
    return this.axiosIns.get(this.jwtConfig.reportPropertySlotsData, { params: { apartmentId: apartment ? apartment.id : null } });
  }

  // report tenant
  getReportTenantGeneralData(apartment) {
    return this.axiosIns.get(this.jwtConfig.reportTenantGeneralData, { params: { filter: { apartmentId: apartment ? apartment.id : null } } });
  }

  // report task
  getReportTaskGeneralData(apartment) {
    return this.axiosIns.get(this.jwtConfig.reportTaskGeneralData, { params: { filter: { apartmentId: apartment ? apartment.id : null } } });
  }

  // report contract
  getReportContractGeneralData(apartment) {
    return this.axiosIns.get(this.jwtConfig.reportContractGeneralData, { params: { filter: { apartmentId: apartment ? apartment.id : null } } });
  }

  // report finance
  getReportFinanceIncomeThisMonth(apartment) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceIncomeThisMonth, { params: { apartmentId: apartment ? apartment.id : null } });
  }

  getReportFinanceExpenseThisMonth(apartment) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceExpenseThisMonth, { params: { apartmentId: apartment ? apartment.id : null } });
  }

  getReportFinanceIncomeAllocationThisMonth(apartment) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceIncomeAllocationThisMonth, { params: { filter: { apartmentId: apartment ? apartment.id : null } } });
  }

  getReportFinanceExpenseAllocationThisMonth(apartment) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceExpenseAllocationThisMonth, { params: { filter: { apartmentId: apartment ? apartment.id : null } } });
  }

  getReportFinanceDebt(params) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceDebt, { params });
  }

  getReportBillingCalendar(params) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceBillingCalendar, { params });
  }

  exportReportFinanceDebt(params) {
    return this.axiosIns.get(this.jwtConfig.reportFinanceDebtExport, { params });
  }

  // prepaid
  createPrepaid(...args) {
    return this.axiosIns.post(this.jwtConfig.prepaidCreateEndpoint, ...args);
  }

  updatePrepaid(data) {
    return this.axiosIns.put(`${this.jwtConfig.prepaidUpdateEndpoint}/${data.id}`, data);
  }

  getPrepaids(params) {
    return this.axiosIns.get(this.jwtConfig.prepaidListEndpoint, {
      params,
    });
  }

  exportPrepaids(params) {
    return this.axiosIns.get(this.jwtConfig.prepaidExportEndpoint, {
      params,
    });
  }

  getPrepaidDetailByContract(params) {
    return this.axiosIns.get(this.jwtConfig.prepaidDetailByContractEndpoint, {
      params,
    });
  }

  getRemainPrepaidByContract(params) {
    return this.axiosIns.get(this.jwtConfig.prepaidRemainByContractEndpoint, {
      params,
    });
  }

  deletePrepaid(data) {
    return this.axiosIns.delete(this.jwtConfig.prepaidDeleteEndpoint, { data });
  }

  // Payment
  createPaymentUrl(...args) {
    return this.axiosIns.post(this.jwtConfig.paymentCreatePaymentUrl, ...args);
  }

  validateResult(params) {
    return this.axiosIns.get(this.jwtConfig.paymentValidateResultUrl, {
      params,
    });
  }

  // Plan
  getPlans(params) {
    return this.axiosIns.get(this.jwtConfig.planListEndpoint, {
      params,
    });
  }

  getPlanDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.planDetailEndpoint}/${id}`);
  }

  // order
  createOrder(...args) {
    return this.axiosIns.post(this.jwtConfig.orderCreateEndpoint, ...args);
  }

  updateOrder(...args) {
    return this.axiosIns.put(this.jwtConfig.orderUpdateEndpoint, ...args);
  }

  getOrders(params) {
    return this.axiosIns.get(this.jwtConfig.orderListEndpoint, {
      params,
    });
  }

  deleteOrders(data) {
    return this.axiosIns.delete(this.jwtConfig.orderDeleteEndpoint, { data });
  }

  // task type
  createTaskType(...args) {
    return this.axiosIns.post(this.jwtConfig.taskTypeCreateEndpoint, ...args);
  }

  updateTaskType(object) {
    const { id, data } = object;
    return this.axiosIns.put(`${this.jwtConfig.taskTypeUpdateEndpoint}/${id}`, data);
  }

  getTaskTypes(params) {
    return this.axiosIns.get(this.jwtConfig.taskTypeListEndpoint, {
      params,
    });
  }

  deleteTaskTypes(data) {
    return this.axiosIns.delete(this.jwtConfig.taskTypeDeleteEndpoint, { data });
  }

  // task type
  createSignature(...args) {
    return this.axiosIns.post(this.jwtConfig.signatureCreateEndpoint, ...args);
  }

  updateSignature(object) {
    const { id, data } = object;
    return this.axiosIns.post(`${this.jwtConfig.signatureUpdateEndpoint}/${id}`, data);
  }

  getSignatures(params) {
    return this.axiosIns.get(this.jwtConfig.signatureListEndpoint, {
      params,
    });
  }

  deleteSignatures(data) {
    return this.axiosIns.delete(this.jwtConfig.signatureDeleteEndpoint, { data });
  }

  // report property
  getReportEmptySlots(params) {
    return this.axiosIns.get(this.jwtConfig.reportPropertyEmptySlots, {
      params,
    });
  }

  exportReportEmptySlots(params) {
    return this.axiosIns.get(this.jwtConfig.reportPropertyEmptyExportSlots, {
      params,
    });
  }

  getReportPercentageSlots(params) {
    return this.axiosIns.get(this.jwtConfig.reportPropertyPercentageSlots, {
      params,
    });
  }

  exportReportPercentageSlots(params) {
    return this.axiosIns.get(this.jwtConfig.reportPropertyPercentageExportSlots, {
      params,
    });
  }

  getReportNearEmptySlots(params) {
    return this.axiosIns.get(this.jwtConfig.reportPropertyNearEmptySlots, {
      params,
    });
  }

  getReportSpecialContracts(params) {
    return this.axiosIns.get(this.jwtConfig.reportPropertySpecialContract, {
      params,
    });
  }

  exportReportSpecialContracts(params) {
    return this.axiosIns.get(this.jwtConfig.reportPropertySpecialContractExport, {
      params,
    });
  }

  exportReportNearEmptySlots(params) {
    return this.axiosIns.get(this.jwtConfig.reportPropertyNearEmptySlotsExport, {
      params,
    });
  }

  // report finance
  getIncomeExpenseChartByYear(params) {
    return this.axiosIns.get(this.jwtConfig.reportIncomeExpenseChartByYear, {
      params,
    });
  }

  getIncomeExpenseHTMLByYear(params) {
    return this.axiosIns.get(this.jwtConfig.reportIncomeExpenseHTMLByYear, {
      params,
    });
  }

  getIncomeExpenseHTMLByType(params) {
    return this.axiosIns.get(this.jwtConfig.reportIncomeExpenseHTMLByType, {
      params,
    });
  }

  // admin
  getHosts(params) {
    return this.axiosIns.get(this.jwtConfig.adminListHost, {
      params,
    });
  }

  getHostAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.adminListHostAnalytics, {
      params,
    });
  }

  getHostDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.adminHostDetail}/${id}`);
  }

  updateHostSubscription(data) {
    return this.axiosIns.post(this.jwtConfig.adminHostSubscription, data);
  }

  syncHostDataFromItro(data) {
    return this.axiosIns.post(this.jwtConfig.adminHostSyncDataFromOldSystem, data);
  }

  changeHostPassword(data) {
    return this.axiosIns.post(this.jwtConfig.adminHostChangePassword, data);
  }

  getAdminOrders(params) {
    return this.axiosIns.get(this.jwtConfig.adminListOrder, {
      params,
    });
  }

  getOrderAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.adminListOrderAnalytics, {
      params,
    });
  }

  getOrderDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.adminOrderDetail}/${id}`);
  }

  getAdminTenants(params) {
    return this.axiosIns.get(this.jwtConfig.adminListTenant, {
      params,
    });
  }

  getAdminTenantAnalytics(params) {
    return this.axiosIns.get(this.jwtConfig.adminListTenantAnalytics, {
      params,
    });
  }

  getAdminTenantDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.adminTenantDetail}/${id}`);
  }

  // attachment
  uploadAttachment(data) {
    return this.axiosIns.post(this.jwtConfig.attachmentUploadEndpoint, data);
  }

  deleteAttachment(data) {
    return this.axiosIns.delete(this.jwtConfig.attachmentDeleteEndpoint, { data });
  }

  // Job
  fetchJobs(params) {
    return this.axiosIns.get(this.jwtConfig.jobEndPoint, { params });
  }

  getJobDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.jobEndPoint}/${id}`);
  }

  reRunJob(id) {
    return this.axiosIns.get(`${this.jwtConfig.jobReRunEndPoint}/${id}`);
  }

  // sale path
  getSalePathData(salePath) {
    return this.axiosIns.get(this.jwtConfig.salePathDataEndPoint, { params: { salePath } });
  }

  // System log
  fetchSystemLogs(params) {
    return this.axiosIns.get(this.jwtConfig.systemLogEndPoint, { params });
  }

  // banner
  getAdminBanners(params) {
    return this.axiosIns.get(this.jwtConfig.bannerListEndpoint, { params });
  }

  createBanner(...args) {
    return this.axiosIns.post(this.jwtConfig.bannerCreateEndpoint, ...args);
  }

  updateBanner(object) {
    const { data, id } = object;
    return this.axiosIns.post(`${this.jwtConfig.bannerUpdateEndpoint}/${id}`, data);
  }

  deleteBanner(data) {
    return this.axiosIns.delete(this.jwtConfig.bannerDeleteEndpoint, { data });
  }

  // location
  createLocation(...args) {
    return this.axiosIns.post(this.jwtConfig.locationCreateEndpoint, ...args);
  }

  updateLocation(object) {
    const { data, id } = object;
    return this.axiosIns.put(`${this.jwtConfig.locationUpdateEndpoint}/${id}`, data);
  }

  getLocationDetail(data) {
    const { bedId } = data;
    return this.axiosIns.get(`${this.jwtConfig.locationDetailEndpoint}/${bedId}`);
  }

  getLocations(params) {
    return this.axiosIns.get(this.jwtConfig.locationListEndpoint, {
      params,
    });
  }

  deleteLocations(data) {
    return this.axiosIns.delete(this.jwtConfig.locationDeleteEndpoint, { data });
  }

  // hotline
  createHotline(...args) {
    return this.axiosIns.post(this.jwtConfig.hotlineCreateEndpoint, ...args);
  }

  updateHotline(object) {
    const { data, id } = object;
    return this.axiosIns.put(`${this.jwtConfig.hotlineUpdateEndpoint}/${id}`, data);
  }

  getHotlineDetail(data) {
    const { bedId } = data;
    return this.axiosIns.get(`${this.jwtConfig.hotlineDetailEndpoint}/${bedId}`);
  }

  getHotlines(params) {
    return this.axiosIns.get(this.jwtConfig.hotlineListEndpoint, {
      params,
    });
  }

  deleteHotlines(data) {
    return this.axiosIns.delete(this.jwtConfig.hotlineDeleteEndpoint, { data });
  }

  // bank
  getBanks(params) {
    return this.axiosIns.get(this.jwtConfig.bankListEndpoint, {
      params,
    });
  }

  getBankAvailableApiConnect(params) {
    return this.axiosIns.get(this.jwtConfig.bankApiConnectOnlyEndpoint, {
      params,
    });
  }

  // depositTemplate
  createDepositTemplate(...args) {
    return this.axiosIns.post(this.jwtConfig.depositTemplateCreateEndpoint, ...args);
  }

  updateDepositTemplate(object) {
    const { data, id } = object;
    return this.axiosIns.put(`${this.jwtConfig.depositTemplateUpdateEndpoint}/${id}`, data);
  }

  previewDepositTemplate(id) {
    return this.axiosIns.get(`${this.jwtConfig.depositTemplatePreviewEndpoint}/${id}/preview`);
  }

  getDepositTemplateDetail(data) {
    const { bedId } = data;
    return this.axiosIns.get(`${this.jwtConfig.depositTemplateDetailEndpoint}/${bedId}`);
  }

  getDepositTemplates(params) {
    return this.axiosIns.get(this.jwtConfig.depositTemplateListEndpoint, {
      params,
    });
  }

  deleteDepositTemplates(data) {
    return this.axiosIns.delete(this.jwtConfig.depositTemplateDeleteEndpoint, { data });
  }

  // contract template
  createContractTemplate(...args) {
    return this.axiosIns.post(this.jwtConfig.contractTemplateCreateEndpoint, ...args);
  }

  updateContractTemplate(object) {
    const { data, id } = object;
    return this.axiosIns.put(`${this.jwtConfig.contractTemplateUpdateEndpoint}/${id}`, data);
  }

  previewContractTemplate(id) {
    return this.axiosIns.get(`${this.jwtConfig.contractTemplatePreviewEndpoint}/${id}/preview`);
  }

  getContractTemplateDetail(data) {
    const { bedId } = data;
    return this.axiosIns.get(`${this.jwtConfig.contractTemplateDetailEndpoint}/${bedId}`);
  }

  getContractTemplates(params) {
    return this.axiosIns.get(this.jwtConfig.contractTemplateListEndpoint, {
      params,
    });
  }

  deleteContractTemplates(data) {
    return this.axiosIns.delete(this.jwtConfig.contractTemplateDeleteEndpoint, { data });
  }

  // handover template
  createHandoverTemplate(...args) {
    return this.axiosIns.post(this.jwtConfig.handoverTemplateCreateEndpoint, ...args);
  }

  updateHandoverTemplate(object) {
    const { data, id } = object;
    return this.axiosIns.put(`${this.jwtConfig.handoverTemplateUpdateEndpoint}/${id}`, data);
  }

  previewHandoverTemplate(id) {
    return this.axiosIns.get(`${this.jwtConfig.handoverTemplatePreviewEndpoint}/${id}/preview`);
  }

  getHandoverTemplateDetail(data) {
    const { bedId } = data;
    return this.axiosIns.get(`${this.jwtConfig.handoverTemplateDetailEndpoint}/${bedId}`);
  }

  getHandoverTemplates(params) {
    return this.axiosIns.get(this.jwtConfig.handoverTemplateListEndpoint, {
      params,
    });
  }

  deleteHandoverTemplates(data) {
    return this.axiosIns.delete(this.jwtConfig.handoverTemplateDeleteEndpoint, { data });
  }

  // invoice template
  createInvoiceTemplate(...args) {
    return this.axiosIns.post(this.jwtConfig.invoiceTemplateCreateEndpoint, ...args);
  }

  updateInvoiceTemplate(object) {
    const { data, id } = object;
    return this.axiosIns.put(`${this.jwtConfig.invoiceTemplateUpdateEndpoint}/${id}`, data);
  }

  previewInvoiceTemplate(id) {
    return this.axiosIns.get(`${this.jwtConfig.invoiceTemplatePreviewEndpoint}/${id}/preview`);
  }

  getInvoiceTemplateDetail(data) {
    const { bedId } = data;
    return this.axiosIns.get(`${this.jwtConfig.invoiceTemplateDetailEndpoint}/${bedId}`);
  }

  getInvoiceTemplates(params) {
    return this.axiosIns.get(this.jwtConfig.invoiceTemplateListEndpoint, {
      params,
    });
  }

  deleteInvoiceTemplates(data) {
    return this.axiosIns.delete(this.jwtConfig.invoiceTemplateDeleteEndpoint, { data });
  }

  // smart device
  createSmartDevice(...args) {
    return this.axiosIns.post(this.jwtConfig.smartDeviceCreateEndpoint, ...args);
  }

  updateSmartDevice(data) {
    return this.axiosIns.put(`${this.jwtConfig.smartDeviceUpdateEndpoint}/${data.id}`, data);
  }

  getSmartDevices(params) {
    return this.axiosIns.get(this.jwtConfig.smartDeviceListEndpoint, {
      params,
    });
  }

  getSmartDeviceDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.smartDeviceDetailEndpoint}/${id}`);
  }

  deleteSmartDevices(data) {
    return this.axiosIns.delete(this.jwtConfig.smartDeviceDeleteEndpoint, { data });
  }

  syncVConnexAccountWithCode(data) {
    return this.axiosIns.post(this.jwtConfig.smartDeviceSyncVConnexWithCodeEndpoint, data);
  }

  fetchVConnexAuthorizationUrl() {
    return this.axiosIns.get(this.jwtConfig.smartDeviceSyncVConnexAuthEndpoint);
  }

  linkSmartDevice(data) {
    return this.axiosIns.post(this.jwtConfig.smartDeviceLinkEndpoint, data);
  }

  getTuyaAccount() {
    return this.axiosIns.get(this.jwtConfig.smartDeviceGetTuyaAccountEndpoint);
  }

  syncTuyaDevices() {
    return this.axiosIns.get(this.jwtConfig.smartDeviceSyncTuyaDevicesEndpoint);
  }

  changeDeviceStatus(data) {
    return this.axiosIns.post(this.jwtConfig.smartDeviceChangeStatusEndpoint, data);
  }

  // tingee

  getTingeeAccounts() {
    return this.axiosIns.get(this.jwtConfig.tingeeListEndpoint);
  }

  connectBankAccount(data) {
    return this.axiosIns.post(this.jwtConfig.tingeeConnectBankAccountEndpoint, data);
  }

  verifyBankAccount(data) {
    return this.axiosIns.post(this.jwtConfig.tingeeVerifyBankAccountEndpoint, data);
  }

  tingeeVerifyOCBInfo(...args) {
    return this.axiosIns.post(this.jwtConfig.tingeeVerifyOCBInfoEndpoint, ...args);
  }

  tingeeVerifyOCBInfoWithOtp(...args) {
    return this.axiosIns.post(this.jwtConfig.tingeeVerifyOCBInfoWithOtpEndpoint, ...args);
  }

  tingeeOCBCreateVAAccount(...args) {
    return this.axiosIns.post(this.jwtConfig.tingeeOCBCreateVAAccountEndpoint, ...args);
  }

  tingeeGetOCBAccount() {
    return this.axiosIns.get(this.jwtConfig.tingeeOCBAccountEndpoint);
  }

  // other handover
  getOtherHandoverTingTong() {
    return this.axiosIns.get(this.jwtConfig.otherHandoverTingTongDefaultEndpoint);
  }

  getOtherHandoverTingTongByContract(params) {
    return this.axiosIns.get(this.jwtConfig.otherHandoverTingTongByContractEndpoint, { params });
  }

  // conversation
  getConversations(params) {
    return this.axiosIns.get(this.jwtConfig.conversationListEndpoint, { params });
  }

  getConversationDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.conversationDetailEndpoint}/${id}`);
  }

  getConversationMessages(params) {
    return this.axiosIns.get(this.jwtConfig.conversationMessagesEndpoint, { params });
  }

  createConversation(data) {
    return this.axiosIns.post(this.jwtConfig.conversationCreateEndpoint, data);
  }

  //Zalo 

  getZaloLinkURL() {
    return this.axiosIns.get(this.jwtConfig.zaloLinkURLEndpoint);
  }

  linkZaloOA(data) {
    return this.axiosIns.post(this.jwtConfig.zaloLinkOAEndpoint, data);
  }

  getZalos(params) {
    return this.axiosIns.get(this.jwtConfig.zaloListEndpoint, {
      params,
    });
  }


  deleteZalos(data) {
    return this.axiosIns.delete(this.jwtConfig.zaloDeleteEndpoint, { data });
  }
}
