export default [
  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/tasks',
    name: 'apps-todo',
    component: () => import('@/views/apps/task/list/TaskList.vue'),
    meta: {
      resource: 'task',
      action: 'read',
    },
  },

  // {
  //   path: '/tasks',
  //   name: 'apps-todo',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //   },
  // },
  // {
  //   path: '/tasks/:filter',
  //   name: 'apps-todo-filter',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['important', 'completed', 'deleted'].includes(to.params.filter)) {
  //       next();
  //     } else next({ name: 'error-404' });
  //   },
  // },
  // {
  //   path: '/tasks/priority/:tag',
  //   name: 'apps-todo-tag',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) {
  //       next();
  //     } else next({ name: 'error-404' });
  //   },
  // },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      resource: 'message',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- NOTIFICATION  ---------------------------------------*
  // *===============================================---*
  {
    path: '/notification',
    name: 'apps-notification',
    component: () => import('@/views/apps/notification/list/NotificationList.vue'),
    meta: {
      resource: 'notification',
      action: 'read',
    },
  },
  {
    path: '/system-notification',
    name: 'system-notification',
    component: () => import('@/views/apps/notification/list-system/SystemNotificationList.vue'),
    meta: {
      resource: 'notification',
      action: 'read',
    },
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('@/views/apps/job/list/JobList.vue'),
    meta: {
      resource: 'profile',
      action: 'read',
    },
  },
  {
    path: '/system-logs',
    name: 'system-logs',
    component: () => import('@/views/apps/system-log/list/SystemLogList.vue'),
    meta: {
      resource: 'profile',
      action: 'read',
    },
  },
];
