export default [
  {
    path: '/admin/apartments',
    name: 'apartment-admin-list',
    component: () => import('@/views/admin/apartment/apartment-list/ApartmentList.vue'),
    meta: {
      resource: 'apartment-admin',
      action: 'manage',
    },
  },
  {
    path: '/admin/hosts',
    name: 'host-admin-list',
    component: () => import('@/views/admin/host/list/HostList.vue'),
    meta: {
      resource: 'host-admin',
      action: 'manage',
    },
  },
  {
    path: '/admin/tenants',
    name: 'tenant-admin-list',
    component: () => import('@/views/admin/tenant/list/TenantList.vue'),
    meta: {
      resource: 'tenant-admin',
      action: 'manage',
    },
  },
  {
    path: '/admin/orders',
    name: 'order-admin-list',
    component: () => import('@/views/admin/order/list/OrderList.vue'),
    meta: {
      resource: 'order-admin',
      action: 'manage',
    },
  },
  {
    path: '/admin/banners',
    name: 'banners',
    component: () => import('@/views/admin/banner/list/BannerList.vue'),
    meta: {
      resource: 'banner-admin',
      action: 'manage',
    },
  },
];
