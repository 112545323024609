import Vue from 'vue';

// axios
import axios from 'axios';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  headers: { 'Access-Control-Allow-Origin': '*', 'x-lang': process.env.VUE_APP_PROVIDER === 'vmarket' ? "vi" : "vi" },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
