export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/view/ProfileView.vue'),
    meta: {
      resource: 'profile',
      action: 'read',
    },
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/views/pricing/Pricing.vue'),
    meta: {
      resource: 'pricing',
      action: 'read',
    },
  },
  {
    path: '/renew/:slug',
    name: 'renew',
    component: () => import('@/views/pricing/renew/Renew.vue'),
    meta: {
      resource: 'pricing',
      action: 'read',
    },
  },
  {
    path: '/payment-result',
    name: 'payment-result',
    component: () => import('@/views/pricing/payment-result/PaymentResult.vue'),

    meta: {
      layout: 'full',
      resource: 'pricing',
      action: 'read',
    },
  },
];
