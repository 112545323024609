export default [
  {
    path: '/sale/:slug',
    name: 'sale',
    component: () => import('@/views/sale/SaleEmptyRoom.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
];
