import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFees(ctx, params) {
      return useJwt.getFees(params).then(response => response);
    },
    createFee(ctx, data) {
      return useJwt.createFee(data).then(response => response);
    },
    updateFee(ctx, data) {
      return useJwt.updateFee(data).then(response => response);
    },
    getFeeDetail(ctx, id) {
      return useJwt.getFeeDetail(id).then(response => response);
    },
    deleteFees(ctx, data) {
      return useJwt.deleteFees(data).then(response => response);
    },
  },
};
