import useJwt from "@/auth/jwt/useJwt";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrders(ctx, params) {
      return useJwt.getOrders(params).then(response => response);
    },
    createOrder(ctx, data) {
      return useJwt.createOrder(data).then(response => response);
    },
    updateOrder(ctx, data) {
      return useJwt.updateOrder(data).then(response => response);
    },
    deleteOrders(ctx, data) {
      return useJwt.deleteOrders(data).then(response => response);
    },

  },
};
