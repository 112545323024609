export default [
  {
    path: '/role/list',
    name: 'role-list',
    component: () => import('@/views/permission/role/list/RoleList.vue'),
    meta: {
      resource: 'role',
      action: 'read',
    },
  },
  {
    path: '/manager/list',
    name: 'manager-list',
    component: () => import('@/views/permission/manager/list/ManagerList.vue'),
    meta: {
      resource: 'manager',
      action: 'read',
    },
  },
];
