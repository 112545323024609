export default [
  {
    path: '/shortcuts',
    name: 'shortcuts',
    component: () => import('@/views/shortcut/ShortCut.vue'),
    meta: {
      resource: 'shortcut',
      action: 'read',
    },
  },
];
