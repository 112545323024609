export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/settings/Settings.vue'),
    meta: {
      resource: 'setting',
      action: 'read',
    },
  },
  {
    path: '/setting/provinces',
    name: 'setting-place-province',
    component: () => import('@/views/settings/place/provinces/list/ProvinceList.vue'),
    meta: {
      resource: 'setting',
      action: 'read',
    },
  },
  {
    path: '/setting/districts',
    name: 'setting-place-district',
    component: () => import('@/views/settings/place/districts/DistrictList.vue'),
    meta: {
      resource: 'setting',
      action: 'read',
    },
  },
  {
    path: '/setting/wards',
    name: 'setting-place-ward',
    component: () => import('@/views/settings/place/wards/WardList.vue'),
    meta: {
      resource: 'setting',
      action: 'read',
    },
  },
  {
    path: '/setting/income-expense-type-list',
    name: 'setting-income-expense-type-list',
    component: () => import('@/views/settings/etc/income-expense-type/list/IncomeExpenseTypeList.vue'),
    meta: {
      resource: 'setting',
      action: 'read',
    },
  },
  {
    path: '/setting/signature-template',
    name: 'setting-signature-template',
    component: () => import('@/views/settings/template/signature/list/SignatureList.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/setting/deposit-template',
    name: 'setting-deposit-template',
    component: () => import('@/views/settings/template/deposit/list/DepositTemplateList.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/setting/contract-template',
    name: 'setting-contract-template',
    // component: () => import('@/views/settings/template/contract-template/list/ContractTemplateList.vue'),
    component: () => import('@/views/settings/template/contract/ContractTemplate.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/setting/contract-template-v2',
    name: 'setting-contract-template-v2',
    component: () => import('@/views/settings/template/contract-template/list/ContractTemplateList.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/setting/handover-template',
    name: 'setting-handover-template',
    component: () => import('@/views/settings/template/handover-template/list/HandoverTemplateList.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/setting/invoice-template',
    name: 'setting-invoice-template',
    component: () => import('@/views/settings/template/invoice/InvoiceTemplate.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/setting/invoice-template-v2',
    name: 'setting-invoice-template-v2',
    component: () => import('@/views/settings/template/invoice-template/list/InvoiceTemplateList.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/setting/income-template',
    name: 'setting-income-template',
    component: () => import('@/views/settings/template/income/IncomeTemplate.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/setting/expense-template',
    name: 'setting-expense-template',
    component: () => import('@/views/settings/template/expense/ExpenseTemplate.vue'),
    meta: {
      resource: 'template',
      action: 'read',
    },
  },
  {
    path: '/hotlines',
    name: 'hotlines',
    component: () => import('@/views/settings/hotline/list/HotlineList.vue'),
    meta: {
      resource: 'hotline',
      action: 'read',
    },
  },
  {
    path: '/zalo',
    name: 'zalo',
    component: () => import('@/views/settings/zalo/list/ZaloList.vue'),
    meta: {
      resource: 'zalo',
      action: 'read',
    },
  },
];
