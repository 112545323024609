export default [
  {
    path: '/contract/list',
    name: 'contract-list',
    component: () => import('@/views/contract/contract/list/ContractList.vue'),
    meta: {
      resource: 'contract',
      action: 'read',
    },
  },
  {
    path: '/reservation/list',
    name: 'reservation-list',
    component: () => import('@/views/contract/reservation/list/ReservationList.vue'),
    meta: {
      resource: 'reservation',
      action: 'read',
    },
  },
  {
    path: '/tenant/list',
    name: 'tenant-list',
    component: () => import('@/views/contract/tenant/list/TenantList.vue'),
    meta: {
      resource: 'tenant',
      action: 'read',
    },
  },
  {
    path: '/lead/list',
    name: 'lead-list',
    component: () => import('@/views/contract/lead/list/LeadList.vue'),
    meta: {
      resource: 'lead',
      action: 'read',
    },
  },
  {
    path: '/vehicle/list',
    name: 'vehicle-list',
    component: () => import('@/views/contract/vehicle/list/VehicleList.vue'),
    meta: {
      resource: 'vehicle',
      action: 'read',
    },
  },
];
