export default [
  {
    path: '/inventory/provider/list',
    name: 'provider-list',
    component: () => import('@/views/inventory/provider/list/ProviderList.vue'),
    meta: {
      resource: 'provider',
      action: 'read',
    },
  },
  {
    path: '/inventory/warehouse/list',
    name: 'warehouse-list',
    component: () => import('@/views/inventory/warehouse/list/WarehouseList.vue'),
    meta: {
      resource: 'warehouse',
      action: 'read',
    },
  },
  {
    path: '/inventory/asset-type/list',
    name: 'asset-type-list',
    component: () => import('@/views/inventory/asset-type/list/AssetTypeList.vue'),
    meta: {
      resource: 'asset-type',
      action: 'read',
    },
  },
  {
    path: '/inventory/asset/list',
    name: 'asset-list',
    component: () => import('@/views/inventory/asset/list/AssetList.vue'),
    meta: {
      resource: 'asset',
      action: 'read',
    },
  },
  {
    path: '/smart-device/list',
    name: 'smart-device-list',
    component: () => import('@/views/smart-device/list/SmartDeviceList.vue'),
    meta: {
      resource: 'smart-device',
      action: 'read',
    },
  },
  {
    path: '/smart-device/vconnex/callback',
    name: 'smart-device-vconnex-callback',
    component: () => import('@/views/smart-device/vconnex/VconnexCallback.vue'),
    meta: {
      resource: 'smart-device',
      action: 'read',
      layout: 'full',
    },
  },
  {
    path: '/inventory/move-asset-log',
    name: 'asset-log-list',
    component: () => import('@/views/inventory/asset-log/list/AssetLogList.vue'),
    meta: {
      resource: 'asset',
      action: 'read',
    },
  },
];
