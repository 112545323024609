import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUserConfiguration(ctx, params) {
      return useJwt.getUserConfiguration(params).then(response => {
        localStorage.setItem('userConfiguration', JSON.stringify(response.data));
        return response;
      });
    },
  },
};
