export default [
  {
    path: '/location/list',
    name: 'location-list',
    component: () => import('@/views/property/location/list/LocationList.vue'),
    meta: {
      resource: 'property',
      action: 'read',
    },
  },
  {
    path: '/apartment/list',
    name: 'apartment-list',
    component: () => import('@/views/property/apartment/apartment-list/ApartmentList.vue'),
    meta: {
      resource: 'apartment',
      action: 'read',
    },
  },
  {
    path: '/room/list',
    name: 'room-list',
    component: () => import('@/views/property/room/list/RoomList.vue'),
    meta: {
      resource: 'room',
      action: 'read',
    },
  },
  {
    path: '/bed/list',
    name: 'bed-list',
    component: () => import('@/views/property/bed/list/BedList.vue'),
    meta: {
      resource: 'bed',
      action: 'read',
    },
  },
];
