import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getUserProfile() {
      return useJwt.getUserProfile().then(response => response);
    },
    updateProfile(ctx, data) {
      return useJwt.updateUserProfile(data).then(response => response);
    },
    changePassword(ctx, data) {
      return useJwt.changeUserPassword(data).then(response => response);
    },
    requestVerifyPhoneOtp(ctx, data) {
      return useJwt.requestVerifyPhoneOtp(data).then(response => response);
    },
    verifyPhone(ctx, data) {
      return useJwt.verifyPhone(data).then(response => response);
    },
  },
};
