import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReservations(ctx, params) {
      return useJwt.getReservations(params).then(response => response);
    },
    exportReservations(xtc, params) {
      return useJwt.exportReservations(params).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getReservationAnalytics(params).then(response => response);
    },
    createReservation(ctx, data) {
      return useJwt.createReservation(data).then(response => response);
    },
    updateReservation(ctx, data) {
      return useJwt.updateReservation(data).then(response => response);
    },
    terminateReservation(ctx, data) {
      return useJwt.terminateReservation(data).then(response => response);
    },
    getReservationDetail(ctx, id) {
      return useJwt.getReservationDetail(id).then(response => response);
    },
    deleteReservations(ctx, data) {
      return useJwt.deleteReservations(data).then(response => response);
    },
    getActiveReservation(ctx, data) {
      return useJwt.getActiveReservation(data).then(response => response);
    },
    previewReservation(ctx, id) {
      return useJwt.previewReservation(id).then(response => response);
    },
  },
};
