import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPrepaids(ctx, params) {
      return useJwt.getPrepaids(params).then(response => response);
    },
    exportPrepaids(ctx, params) {
      return useJwt.exportPrepaids(params).then(response => response);
    },
    getPrepaidDetailByContract(ctx, params) {
      return useJwt.getPrepaidDetailByContract(params).then(response => response);
    },
    getPrepaidRemainByContract(ctx, params) {
      return useJwt.getRemainPrepaidByContract(params).then(response => response);
    },
    createPrepaid(ctx, data) {
      return useJwt.createPrepaid(data).then(response => response);
    },
    updatePrepaid(ctx, data) {
      return useJwt.updatePrepaid(data).then(response => response);
    },
    deletePrepaid(ctx, data) {
      return useJwt.deletePrepaid(data).then(response => response);
    },
  },
};
