export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/calendar/Calendar.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
    },
  },
];
