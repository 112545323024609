import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getPlans(ctx, params) {
      return useJwt.getPlans(params).then(response => response);
    },

    getPlanDetail(ctx, id) {
      return useJwt.getPlanDetail(id).then(response => response);
    },

    createPaymentUrl(ctx, data) {
      return useJwt.createPaymentUrl(data).then(response => response);
    },

    validateResult(xtc, params) {
      return useJwt.validateResult(params).then(response => response);
    },
  },
};
